import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Table } from 'semantic-ui-react';
import { usePopup } from '../../lib/popup';
import { useField } from '../../hooks';
import { Input } from '../../lib/custom-ui';

import UserAddStepContainer from '../../containers/UserAddStepContainer';
import Item from './Item';

const UsersModal = React.memo(
  ({
    items,
    canAdd,
    onUpdate,
    onUsernameUpdate,
    onUsernameUpdateMessageDismiss,
    onEmailUpdate,
    onEmailUpdateMessageDismiss,
    onPasswordUpdate,
    onPasswordUpdateMessageDismiss,
    onDisableUpdate,
    onDisableUpdateMessageDismiss,
    onDelete,
    onClose,
  }) => {
    const [t] = useTranslation();
    const [search, handleSearchChange] = useField('');
    const cleanSearch = useMemo(() => search.trim().toLowerCase(), [search]);

    const filteredItems = useMemo(
      () =>
        items.filter(
          (item) =>
            (item.name && item.name.toLowerCase().includes(cleanSearch)) ||
            (item.username && item.username.toLowerCase().includes(cleanSearch)) ||
            (item.email && item.email.toLowerCase().includes(cleanSearch)),
        ).sort((a, b) => {
          if (a.isAdmin && !b.isAdmin) {
            return -1;
          }
          if (!a.isAdmin && b.isAdmin) {
            return 1;
          }

          const aDisabled = a.name.toLowerCase().includes("disabled");
          const bDisabled = b.name.toLowerCase().includes("disabled");

          if (aDisabled && !bDisabled) {
            return 1;
          } else if (bDisabled && !aDisabled) {
            return -1;
          }

          return a.name.localeCompare(b.name);
        }), [items, cleanSearch]
    )

    const handleUpdate = useCallback(
      (id, data) => {
        onUpdate(id, data);
      },
      [onUpdate],
    );

    const handleUsernameUpdate = useCallback(
      (id, data) => {
        onUsernameUpdate(id, data);
      },
      [onUsernameUpdate],
    );

    const handleUsernameUpdateMessageDismiss = useCallback(
      (id) => {
        onUsernameUpdateMessageDismiss(id);
      },
      [onUsernameUpdateMessageDismiss],
    );

    const handleEmailUpdate = useCallback(
      (id, data) => {
        onEmailUpdate(id, data);
      },
      [onEmailUpdate],
    );


    const handleEmailUpdateMessageDismiss = useCallback(
      (id) => {
        onEmailUpdateMessageDismiss(id);
      },
      [onEmailUpdateMessageDismiss],
    );

    const handlePasswordUpdate = useCallback(
      (id, data) => {
        onPasswordUpdate(id, data);
      },
      [onPasswordUpdate],
    );

    const handlePasswordUpdateMessageDismiss = useCallback(
      (id) => {
        onPasswordUpdateMessageDismiss(id);
      },
      [onPasswordUpdateMessageDismiss],
    );

    const handleDisableUpdate = useCallback(
      (id, data) => {
        console.log('ON DISABLE UPDATE', id, data);
        onDisableUpdate(id, data);
      },
      [onDisableUpdate],
    );

    const handleDisableUpdateMessageDismiss = useCallback(
      (id) => {
        onDisableUpdateMessageDismiss(id);
      },
      [onDisableUpdateMessageDismiss],
    );

    const handleDelete = useCallback(
      (id) => {
        onDelete(id);
      },
      [onDelete],
    );

    const UserAddPopupContainer = usePopup(UserAddStepContainer);

    const searchField = useRef(null);

    return (
      <Modal open closeIcon size="large" centered={false} onClose={onClose}>
        <Modal.Header>
          {t('common.users', {
            context: 'title',
          })}
        </Modal.Header>
        <Modal.Content scrolling>
          <Input
            fluid
            ref={searchField}
            value={search}
            placeholder="Search Users"
            icon="search"
            onChange={handleSearchChange}
          />
          <Table unstackable basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell width={4}>{t('common.name')}</Table.HeaderCell>
                <Table.HeaderCell width={4}>{t('common.username')}</Table.HeaderCell>
                <Table.HeaderCell width={4}>{t('common.email')}</Table.HeaderCell>
                <Table.HeaderCell width={4}>Discord ID</Table.HeaderCell>
                <Table.HeaderCell>{t('common.administrator')}</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {filteredItems.map((item) => (
                <Item
                  key={item.id}
                  email={item.email}
                  username={item.username}
                  name={item.name}
                  avatarUrl={item.avatarUrl}
                  organization={item.organization}
                  phone={item.phone}
                  isAdmin={item.isAdmin}
                  isLocked={item.isLocked}
                  isRoleLocked={item.isRoleLocked}
                  isUsernameLocked={item.isUsernameLocked}
                  isDeletionLocked={item.isDeletionLocked}
                  emailUpdateForm={item.emailUpdateForm}
                  passwordUpdateForm={item.passwordUpdateForm}
                  usernameUpdateForm={item.usernameUpdateForm}
                  disableUpdateForm={item.disableUpdateForm}
                  discordId={item.discordId}
                  onUpdate={(data) => handleUpdate(item.id, data)}
                  onUsernameUpdate={(data) => handleUsernameUpdate(item.id, data)}
                  onUsernameUpdateMessageDismiss={() => handleUsernameUpdateMessageDismiss(item.id)}
                  onEmailUpdate={(data) => handleEmailUpdate(item.id, data)}
                  onEmailUpdateMessageDismiss={() => handleEmailUpdateMessageDismiss(item.id)}
                  onPasswordUpdate={(data) => handlePasswordUpdate(item.id, data)}
                  onPasswordUpdateMessageDismiss={() => handlePasswordUpdateMessageDismiss(item.id)}
                  onDisableUpdate={(data) => handleDisableUpdate(item.id, data)}
                  onDisableUpdateMessageDismiss={() => handleDisablepdateMessageDismiss(item.id)}
                  onDelete={() => handleDelete(item.id)}
                />
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
        {canAdd && (
          <Modal.Actions>
            <UserAddPopupContainer>
              <Button positive content={t('action.addUser')} />
            </UserAddPopupContainer>
          </Modal.Actions>
        )}
      </Modal>
    );
  },
);

UsersModal.propTypes = {
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  canAdd: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUsernameUpdate: PropTypes.func.isRequired,
  onUsernameUpdateMessageDismiss: PropTypes.func.isRequired,
  onEmailUpdate: PropTypes.func.isRequired,
  onEmailUpdateMessageDismiss: PropTypes.func.isRequired,
  onPasswordUpdate: PropTypes.func.isRequired,
  onPasswordUpdateMessageDismiss: PropTypes.func.isRequired,
  onDisableUpdate: PropTypes.func.isRequired,
  onDisableUpdateMessageDismiss: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UsersModal;
