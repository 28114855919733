import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { usePrevious } from '../../lib/hooks';
import { Popup } from '../../lib/custom-ui';

import styles from './DisableStep.module.scss';

const DisableStep = React.memo(
  ({
    title,
    content,
    buttonContent,
    defaultData,
    isSubmitting,
    onConfirm,
    onBack,
    disable,
  }) => {
    const [t] = useTranslation();
    const wasSubmitting = usePrevious(isSubmitting);

    const handleConfirm = useCallback(() => {
      onConfirm({ ...defaultData, disable });
    }, [onConfirm, disable, defaultData]);

    useEffect(() => {
      if (wasSubmitting && !isSubmitting) {
        onBack();
      }
    }, [isSubmitting, wasSubmitting, onBack]);

    return (
      <>
        <Popup.Header onBack={onBack}>
          {t(title, {
            context: 'title',
          })}
        </Popup.Header>
        <Popup.Content>
          <div className={styles.content}>{t(content)}</div>
          <Button fluid negative content={t(buttonContent)} onClick={handleConfirm} />
        </Popup.Content>
      </>
    );
  });

DisableStep.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  buttonContent: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onConfirm: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  disable: PropTypes.bool.isRequired,
};

DisableStep.defaultProps = {
  onBack: undefined,
};

export default DisableStep;
