/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import truncate from 'lodash/truncate';
import React, { useCallback, useRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Icon, Label } from 'semantic-ui-react';
import { Gallery, Item as GalleryItem } from 'react-photoswipe-gallery';
import { Popup } from '../../lib/custom-ui';

import Paths from '../../constants/Paths';
import { ActivityTypes } from '../../constants/Enums';
import User from '../User';

import styles from './NotificationsStep.module.scss';
import Time from '../Time/time'

/* eslint-disable no-useless-escape */
const mentionRegex = /[[@(]{2}([^\\)\]]+)[)\]\\]*[\[(]{1}[{\/member}]*[0-9]{17,}[\])]{1}/g;
/* eslint-enable no-useless-escape */

function truncatePreservingMentions(text, maxLength) {
  let truncatedText = text.replace(mentionRegex, "@$1 ");

  truncatedText = truncatedText.slice(0, maxLength);
  const lastOpenBracket = truncatedText.lastIndexOf('[');
  const lastCloseBracket = truncatedText.lastIndexOf(']');
  const lastParen = truncatedText.lastIndexOf(')');

  if (lastOpenBracket > lastCloseBracket) {
    truncatedText = truncatedText.slice(0, lastOpenBracket);
  }

  if (lastCloseBracket > lastParen) {
    truncatedText = truncatedText.slice(0, lastCloseBracket + 1);
  }

  return truncatedText;
}

const NotificationsStep = React.memo(({ items, project, onDelete, onClose }) => {
  const [t] = useTranslation();
  const [showRead, setShowRead] = useState(false);
  const [thisProjectOnly, setThisProjectOnly] = useState(false);

  const handleToggleShowRead = () => {
    setShowRead((prev) => !prev);
  };

  const handleToggleProjectOnly = () => {
    setThisProjectOnly((prev) => !prev);
  };

  console.log('all notifs', items);

  const filteredItems = useMemo(
    () =>
      items.filter(
        (item) => {
          if (!item)
            return false;

          if (!showRead && item.isRead)
            return false;

          if (thisProjectOnly && project && item.board?.projectId !== project.id)
            return false;

          return true;
        }),
    [items, project, showRead, thisProjectOnly]
  );

  const handleDelete = useCallback(
    (id) => {
      onDelete(id);
    },
    [onDelete],
  );

  const handleReadAll = useCallback(() => {
    filteredItems.forEach((item) => {
      if (!item.isRead)
        handleDelete(item.id);
    });
  }, [filteredItems, handleDelete]);

  const linkDescriptionContent = ({ activity, card, board }) => {
    return activity && card && board && activity.type !== ActivityTypes.CREATE_ATTACHMENT;
  }

  const renderDescriptionContent =
    ({ activity, card, board }) => {
      switch (activity.type) {
        case ActivityTypes.MOVE_CARD:
          return (
            <>
              {' moved card from '}{activity.data.fromList.name}{' to '}{activity.data.toList.name}
            </>
          );
        case ActivityTypes.COMMENT_CARD: {

          const maxLength = 100;
          const truncatedText = truncatePreservingMentions(activity.data.text, maxLength);

          // const mentions = Array.from(truncatedText.matchAll(mentionRegex)) || [];

          // const commentElements = [];
          // let lastIndex = 0;

          // mentions.forEach(mention => {
          //   const [fullMatch, name, userId] = mention;
          //   const matchIndex = mention.index;

          //   commentElements.push(truncatedText.slice(lastIndex, matchIndex));

          //   commentElements.push(
          //     <a href={`/member/${userId}`} key={userId}>
          //       {name}
          //     </a>
          //   );

          //   lastIndex = matchIndex + fullMatch.length;
          // });

          // if (lastIndex < truncatedText.length) {
          //   commentElements.push(truncatedText.slice(lastIndex));
          // }

          return <span>{' made a comment'}</span>
        }
        case ActivityTypes.MENTION_USER:
          return <span>{' mentioned you in a comment'}</span>
        case ActivityTypes.CREATE_ATTACHMENT:
          return <span>{` attached a file`}</span>
        case ActivityTypes.DELETE_ATTACHMENT:
          return <span>{` deleted an attachment`}</span>
        case ActivityTypes.UPDATE_ATTACHMENT:
          return <span>{` updated an attachment`}</span>
        case ActivityTypes.ARCHIVE_CARD:
          return <span>{` archived card`}</span>
        case ActivityTypes.ADD_USER_TO_TASK:
          return <span>{` assigned you to a task`}</span>
        case ActivityTypes.ADD_USER_TO_CARD:
          return <span>{` added you to the card`}</span>

        default:
          return <span>undefined - NOTIFY ADMINS</span>
      }
    };

  const renderExtraContent =
    ({ activity, card, board }) => {
      switch (activity.type) {
        case ActivityTypes.MENTION_USER:
        case ActivityTypes.COMMENT_CARD: {
          const maxLength = 100;
          const truncatedText = truncatePreservingMentions(activity.data.text, maxLength);

          return (
            <div className={styles.commentBlock}>{truncatedText}</div>
          )
        }
        case ActivityTypes.ADD_USER_TO_TASK: {
          return <div className={styles.commentBlock}>{activity.data.task.name}</div>
        }
        case ActivityTypes.CREATE_ATTACHMENT:
          return (
            <div className={styles.commentBlock}>
              <Gallery
                withCaption
                withDownloadButton
                options={{
                  wheelToZoom: true,
                  showHideAnimationType: 'none',
                  closeTitle: '',
                  zoomTitle: '',
                  arrowPrevTitle: '',
                  arrowNextTitle: '',
                  errorMsg: '',
                }}
              >
                <GalleryItem
                  key={activity.data.attachment.id}
                  original={activity.data.attachment.path}
                  caption={activity.data.attachment.name}
                  width={activity.data.attachment?.image?.width}
                  height={activity.data.attachment?.image?.height}
                >
                  {({ ref, open }) =>
                    <div ref={ref} onClick={() => open()} className={styles.wrapper}>
                      <div
                        style={{
                          background: activity.data.attachment.path && `url("${activity.data.attachment.path}") center / cover`,
                          borderRadius: "3px",
                          height: "80px",
                          left: 0,
                          textAlign: "center",
                          textDecoration: "none",
                          top: "50%",
                          width: "112px",
                          marginLeft: "10px"
                        }}
                      >
                        <Label
                          corner="left"
                          size="mini"
                          icon={{
                            name: 'star',
                            color: 'grey',
                            inverted: true,
                          }}
                          className={styles.thumbnailLabel}
                          style={{ borderColor: "rgba(29, 46, 63, 0.8)" }}
                        />
                      </div>
                    </div>
                  }
                </GalleryItem>
              </Gallery>
            </div>
          );

        default:
      }

      return null;
    };

  const renderItemContent = useCallback(
    ({ activity, card, board }) => {
      switch (activity.type) {
        case ActivityTypes.MOVE_CARD:
          console.log('activity', activity)
          return (
            <div>
              <Trans
                i18nKey="common.userMovedCardFromListToList"
                values={{
                  user: activity.user.name,
                  card: card.name,
                  fromList: activity.data.fromList.name,
                  toList: activity.data.toList.name,
                }}
              >
                <div>
                  {activity.user.name}
                  {' moved '}
                  <Link to={Paths.CARDS.replace(':id', card.id)} onClick={onClose}>
                    {card.name}
                  </Link>
                  {' from '}
                  {activity.data.fromList.name}
                  {' to '}
                  {activity.data.toList.name}
                </div>
              </Trans>
              <div className='text-right'>
                <Time date={activity.createdAt} />
              </div>
            </div>
          );
        case ActivityTypes.COMMENT_CARD: {

          const maxLength = 100;
          const truncatedText = truncatePreservingMentions(activity.data.text, maxLength);

          const mentions = Array.from(truncatedText.matchAll(mentionRegex)) || [];

          const commentElements = [];
          let lastIndex = 0;

          mentions.forEach(mention => {
            const [fullMatch, name, userId] = mention;
            const matchIndex = mention.index;

            commentElements.push(truncatedText.slice(lastIndex, matchIndex));

            commentElements.push(
              <a href={`/member/${userId}`} key={userId}>
                {name}
              </a>
            );

            lastIndex = matchIndex + fullMatch.length;
          });

          if (lastIndex < truncatedText.length) {
            commentElements.push(truncatedText.slice(lastIndex));
          }

          return (
            <div>
              <div>
                {activity.user.name} left a new comment «{commentElements}» to{' '}
                <Link to={Paths.CARDS.replace(':id', card.id)} onClick={onClose}>
                  {card.name}
                </Link>
              </div>
              <div className='text-right'>
                <Time date={activity.createdAt} />
              </div>
            </div>
          );
        }
        case ActivityTypes.MENTION_USER:
          return (
            <div>
              <div>
                {activity.user.name}
                {` mentioned you on `}
                <Link to={Paths.CARDS.replace(':id', card.id)} onClick={onClose}>
                  {card.name}
                </Link>
                {` in `}
                <Link to={Paths.BOARDS.replace(':id', board.id)} onClick={onClose}>
                  {board.name}
                </Link>
              </div>
              <div className='text-right'>
                <Time date={activity.createdAt} />
              </div>
            </div>
          )
        case ActivityTypes.CREATE_ATTACHMENT:
          return (
            <div>
              <div>
                {activity.user.name}
                {` added an attachment to `}
                <Link to={Paths.CARDS.replace(':id', card.id)} onClick={onClose}>
                  {card.name}
                </Link>
                {` in `}
                <Link to={Paths.BOARDS.replace(':id', board.id)} onClick={onClose}>
                  {board.name}
                </Link>
              </div>
              <div className='text-right'>
                <Time date={activity.createdAt} />
              </div>
            </div>
          )
        case ActivityTypes.DELETE_ATTACHMENT:
          return (
            <div>
              <div>
                {activity.user.name}
                {` deleted an attachment on`}
                <Link to={Paths.CARDS.replace(':id', card.id)} onClick={onClose}>
                  {card.name}
                </Link>
                {` in `}
                <Link to={Paths.BOARDS.replace(':id', board.id)} onClick={onClose}>
                  {board.name}
                </Link>
              </div>
              <div className='text-right'>
                <Time date={activity.createdAt} />
              </div>
            </div>
          )
        case ActivityTypes.UPDATE_ATTACHMENT:
          return (
            <div>
              <div>
                {activity.user.name}
                {` updated an attachment on`}
                <Link to={Paths.CARDS.replace(':id', card.id)} onClick={onClose}>
                  {card.name}
                </Link>
                {` in `}
                <Link to={Paths.BOARDS.replace(':id', board.id)} onClick={onClose}>
                  {board.name}
                </Link>
                <div className='text-right'>
                  <Time date={activity.createdAt} />
                </div>
              </div>
            </div>
          )
        case ActivityTypes.ARCHIVE_CARD:
          return (
            <div>
              <div>
                {activity.user.name}
                {` archived `}
                <Link to={Paths.CARDS.replace(':id', card.id)} onClick={onClose}>
                  {card.name}
                </Link>
                {` in `}
                <Link to={Paths.BOARDS.replace(':id', board.id)} onClick={onClose}>
                  {board.name}
                </Link>
                <div className='text-right'>
                  <Time date={activity.createdAt} />
                </div>
              </div>
            </div>
          )

        default:
      }

      return null;
    },
    [onClose],
  );

  const renderTime = (createdAt) => {
    if (createdAt) {
      return (
        <div className="text-right">
          <Time date={createdAt} />
        </div>
      );
    }

    return null;
  }

  return (
    <>
      <Popup.Header className={styles.popupHeader} height='120px'>
        <div className={styles.title}>
          {t('common.notifications', {
            context: 'title',
          })}
        </div>
        <div>
          <div className={styles.projectOnly}>
            {project &&
              <Checkbox
                toggle
                checked={thisProjectOnly}
                label='Current Project Only'
                onChange={handleToggleProjectOnly}
              />
            }
          </div>
          <div className={styles.showRead}>
            <Checkbox
              toggle
              checked={showRead}
              label='Show Read'
              onChange={handleToggleShowRead}
            />
          </div>
        </div>
        <div style={{ paddingTop: '2em' }}>
          <Button fluid className={styles.actionButton} onClick={handleReadAll}>
            <Icon name="times circle outline" className={styles.actionIcon} />
            Read All
          </Button>
        </div>
      </Popup.Header>
      <Popup.Content>
        {filteredItems.length > 0 ? (
          <div className={styles.wrapper}>
            {items.map((item) => (
              <div key={item.id} className={styles.item}>
                {item.card && item.activity && item.board ? (
                  <Link to={Paths.CARDS.replace(':id', item.card.id)} onClick={onClose}>
                    <div className={styles.title}>
                      {`${item.card.name}`}
                      <span style={{ "color": "#4b4b4b" }}>{` [${item.board?.name}]`}</span>
                    </div>
                    <div style={{ "display": "flex" }}>
                      <User
                        name={item.activity.user.name}
                        avatarUrl={item.activity.user.avatarUrl}
                        size="small"
                      />
                      <span className={styles.notificationTypeText}>
                        <b style={{ "color": "black" }}>{item.activity.user.name}</b>
                        {renderDescriptionContent(item)}
                      </span>
                    </div>
                  </Link>
                ) : (
                  <div className={styles.itemDeleted}>{t('common.cardOrActionAreDeleted')}</div>
                )}
                {!item.isRead && <Button
                  type="button"
                  icon="trash alternate outline"
                  className={styles.itemButton}
                  onClick={() => handleDelete(item.id)}
                />}
                {item.card ? (
                  <Link to={Paths.CARDS.replace(':id', item.card.id)} onClick={onClose}>
                    {renderTime(item.activity?.createdAt)}
                  </Link>
                ) : (
                  renderTime(item.activity?.createdAt)
                )}
                {item.card && item.activity && item.board && (
                  linkDescriptionContent(item) ? (
                    <Link to={Paths.CARDS.replace(':id', item.card.id)} onClick={onClose}>
                      <span>{renderExtraContent(item)}</span>
                    </Link>
                  ) : (
                    <span>{renderExtraContent(item)}</span>
                  )
                )}
              </div>
            ))}
          </div>
        ) : (
          t('common.noUnreadNotifications')
        )}
      </Popup.Content>
    </>
  );
});

NotificationsStep.propTypes = {
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  project: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

NotificationsStep.defaultProps = {
  project: undefined,
};

export default NotificationsStep;
