/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { dequal } from 'dequal';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { MentionsInput, Mention } from 'react-mentions';
import { useTranslation } from 'react-i18next';
import { Button, Form, Comment } from 'semantic-ui-react';
import Editor from '../../Editor';

import styles from './CommentEdit.module.scss';

const DEFAULT_DATA = '';

const CommentEdit = React.forwardRef(({ children, defaultData, onUpdate, mentionUsers, isPersisted, text, actions }, ref) => {
  const [t] = useTranslation();
  const [updatedValue, setUpdatedValue] = useState(defaultData);
  const [value, setValue] = useState(defaultData || '');
  const [isLocked, setIsLocked] = useState(true);

  useEffect(() => setValue(defaultData || ''), [defaultData]);
  const editorRef = useRef(null);

  const originalValue = value;

  const open = useCallback(() => {
    setIsLocked(false);
    editorRef.current.refocusEditor();
  }, []);

  const close = useCallback(() => {
    setIsLocked(true);
  }, []);


  const submit = useCallback(() => {
    if (!updatedValue) {
      return;
    }

    onUpdate({
      text: updatedValue.markdown,
      slate: updatedValue.slate,
    });

    setUpdatedValue(DEFAULT_DATA);
    close()

  }, [onUpdate, close, updatedValue]);

  useImperativeHandle(
    ref,
    () => ({
      open,
      close,
    }),
    [open, close],
  );


  const cancel = useCallback(() => {
    editorRef.current.resetEditor();
    setIsLocked(true)
  }, [setIsLocked]);

  const handleSubmit = useCallback(() => {
    submit();
  }, [submit]);

  // useEffect(() => {
  //   if (isOpened) {
  //     // focusEnd(textField.current.ref.current);
  //   }
  // }, [isOpened]);
  //

  // if (!isOpened) {
  //   return (
  //     <>
  //       {actions}
  //       {text}
  //     </>
  //   );
  // }



  return (
    <Form onSubmit={handleSubmit} >
      <div
        // onClick={() => {
        //   if (isLocked) {
        //     open();
        //   }
        // }}
        className="slateEditor"
      >
        <Editor
          value={value}
          ref={editorRef}
          onChange={setUpdatedValue}
          isLocked={isLocked}
          mentionList={mentionUsers}
          editorPlaceholder="Add more detailed description"
        />
        <div className={styles.controls}>
          {!isLocked && (
            <>
              <Button positive content="Save Comment" />
              <Button negative content="Discard Changes" onClick={cancel} />
            </>
          )}
          {isLocked && children}

        </div>

      </div>
    </Form>
  );
});

CommentEdit.propTypes = {
  children: PropTypes.element.isRequired,
  defaultData: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isPersisted: PropTypes.bool.isRequired,
  mentionUsers: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  text: PropTypes.element.isRequired,
  actions: PropTypes.element.isRequired,
};

export default React.memo(CommentEdit);
