import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import { BoardMembershipRoles } from '../constants/Enums';
import List from '../components/List';

const makeMapStateToProps = () => {
  const selectListById = selectors.makeSelectListById();
  const selectCardIdsByListId = selectors.makeSelectCardIdsByListId();
  const selectCollapsedList = selectors.getCollapsedLists();

  return (state, { id, index }) => {
    const { name, color, isPersisted, autoSync } = selectListById(state, id);
    const cardIds = selectCardIdsByListId(state, id);
    const currentUserMembership = selectors.selectCurrentUserMembershipForCurrentBoard(state);
    const allCollapsedLists = selectCollapsedList(state);
    const isCollapsed = allCollapsedLists.some((listId) => listId === id);

    const isCurrentUserEditor =
      !!currentUserMembership && currentUserMembership.role === BoardMembershipRoles.EDITOR;

    return {
      id,
      index,
      name,
      color,
      autoSync,
      isPersisted,
      cardIds,
      canEdit: isCurrentUserEditor,
      isCollapsed,
    };
  };
};

const mapDispatchToProps = (dispatch, { id }) =>
  bindActionCreators(
    {
      onUpdate: (data) => entryActions.updateList(id, data),
      onSort: (data) => entryActions.sortList(id, data),
      onDelete: () => entryActions.deleteList(id),
      onCardCreate: (data, autoOpen) => entryActions.createCard(id, data, autoOpen),
      onSync: (data) => entryActions.syncList(id, data),
      onArchiveCardsInList: () => entryActions.archiveCardsInList(id),
      collapseList: () => entryActions.collapseListInBoard(id),
    },
    dispatch,
  );

export default connect(makeMapStateToProps, mapDispatchToProps)(List);
